.screen-tanks > .header {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.screen-tanks > .header > .download-button-container {
  margin-right: 13px;
  align-self: center;
}

.screen-tanks > .content > .form {
  display: flex;
  justify-content: center;
}
.screen-tanks > .content > .form > .first-container {
  width: 340px;
}

.screen-tanks > .content > .form > .first-container > .tank-photo-container {
  justify-content: center;
}

.screen-tanks > .content > .form > .first-container > .tank-photo-container > .c-image-container > img {
  height: 318px;
  width: 318px;
}

.screen-tanks > .content > .form > .first-container > .tank-status-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.screen-tanks > .content > .form > .first-container > .tank-status-container > .title,
.screen-tanks > .content > .form > .first-container > .tank-status-container > .s-value {
  width: 70px;
  margin-bottom: 0px;
}

.screen-tanks > .content > .form > .first-container > div ,
.screen-tanks > .content > .form > .second-container > div {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.screen-tanks > .content > .form > .first-container > div > .title,
.screen-tanks > .content > .form > .second-container > div > .title {
  text-align: right;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  width: 120px;
}

.screen-tanks > .content > .form > .second-container > .notes-container > .title {
  align-self: flex-start;
  margin-top: 10px;
}

.screen-tanks > .content > .form > .second-container > .notes-container > .value {
  min-height: 50px;
  max-height: 150px;
}

.screen-tanks > .content > .form > .second-container > .carrier-container > .dropdown,
.screen-tanks > .content > .form > .second-container > div .value {
  width: 300px;
}

.screen-tanks > .content > .form > .second-container > .form-actions > .button {
  font-size: 12px;
}

.screen-tanks > .content > .table {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  width: 97%;
  max-height: calc(100vh - 570px);
  overflow: auto;
}

@media only screen and (min-width: 1200px) {
  .screen-tanks > .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .screen-tanks > .content > .table {
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .screen-tanks > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .screen-tanks > .content > .form {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .screen-tanks > .content > .form > .first-container {
    margin-bottom: 10px;
    width: 450px;
  }

  .screen-tanks > .content > .table {
    max-height: 400px;
  }
}
