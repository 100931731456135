.screen-incidences-report > .table {
  margin-top: 24px;
  display: flex;
  overflow: auto;
  height: calc(100vh - 220px);
}

.screen-incidences-report > .search-bar > div:first-child {
  font-size: 14px;
  margin-bottom: 12px;
}

.screen-incidences-report > .search-bar > .content {
  display: flex;
  flex-wrap: wrap;
}

.screen-incidences-report > .search-bar > .content > .actions {
  display: flex;
}

.screen-incidences-report > .search-bar > .content > .actions .button {
  font-size: 12px;
  margin-right: 8px;
}

.screen-incidences-report > .search-bar > .content > .inputs {
  display: flex;
  flex-wrap: wrap;
  margin-right: 22px;
}

.screen-incidences-report > .search-bar > .content > .inputs > div {
  display: flex;
  align-items: center;
}

.screen-incidences-report > .search-bar > .content > .inputs > .date > div {
  width: 170px !important;
}

.screen-incidences-report > .search-bar > .content > .inputs > div label {
  font-weight: bold;
  margin: 0 8px;
}

@media (max-width: 1058px) {
  .screen-incidences-report > .search-bar > .content > .actions {
    margin-top: 12px;
  }
}
