.screen-parkimovil-error-log {
  margin: 20px;
}

.screen-parkimovil-error-log > .search-bar {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.screen-parkimovil-error-log > .search-bar > .field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.screen-parkimovil-error-log > .search-bar > .field > label {
  margin: 0;
  margin-right: 5px;
}

.screen-parkimovil-error-log > .search-bar > .field > label.fix {
  min-width: 160px;
  margin: 0;
  margin-right: 5px;
}
