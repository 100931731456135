.c-tab-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.c-tab-options > .tab-option {
  min-width: 100px;
  min-height: 35px;
  background-color: #e9ecef;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 3px;
  padding: 7px 12px;
  transition: all 0.3s ease-in-out;
}

.c-tab-options > .tab-option > label {
  font-size: 14px;
  margin: 0px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.c-tab-options > .tab-option > label + * {
  color: var(--primary);
  cursor: pointer;
}

.c-tab-options > .tab-option > i {
  margin-bottom: 5px;
}

.c-tab-options > .tab-option.selected {
  background-color: var(--primary);
  color: var(--white);
}

.c-tab-options > .tab-option.selected > label + * {
  color: var(--white);
}

.c-tab-options > .tab-option.selected > svg {
  fill: var(--white);
}
