.screen-traffic-per-shift-v2 {
  margin-left: 10px;
}

.screen-traffic-per-shift-v2 > .search-bar {
  margin-bottom: 10px;
}

.screen-traffic-per-shift-v2 > .search-bar > .row {
  width: 100%;
  margin: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .ui.mini.form > div > #chkWithDates {
  margin-right: 10px;
  cursor: pointer;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .ui.mini.form > div > label {
  cursor: pointer;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > span,
.screen-traffic-per-shift-v2 > .search-bar > .row > .row > span  {
  margin: 0px 10px;
  font-size: 12px;
  font-weight: 700;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > button,
.screen-traffic-per-shift-v2 > .search-bar > .row > .row > button {
  font-size: 12px;
  margin: 0px 15px;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .row > button {
  width: 30px;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .row > button > i {
  transform: translateX(-5px);
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .ui.mini.form,
.screen-traffic-per-shift-v2 > .search-bar > .row > .row > .ui.mini.form {
  min-width: 150px;
  font-size: 11px;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .plants.ui.mini.form {
  min-width: 300px;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .ui.mini.form .ui.dropdown .menu>.item {
  font-size: 11px;
}

.screen-traffic-per-shift-v2 > .search-bar > .row > .separator {
  flex: 1;
}

.screen-traffic-per-shift-v2 > .table {
  overflow-x: auto;
}

.screen-traffic-per-shift-v2 > .table > div > .table-responsive {
  height: calc(100vh - 270px);
  width: 4000px;
}

.modal-body > i.close.icon {
  cursor: pointer;
}

.modal-body > .container > .row > .c-tab-options {
  margin: 0px 20px;
  padding-bottom: 5px;
  width: 100%;
  overflow-x: auto;
}

.modal-body .no-gps-label {
  font-size: 16px;
  flex: 1;
  text-align: center;
  font-weight: bold;
  transform: translateY(6px);
}

.order-info-tab > .row {
  justify-content: space-between;
  margin: 10px;
}

.order-info-tab > .row > .column {
  flex: 1;
}

.order-info-tab > .row > .column > div {
  display: flex;

}

.order-info-tab > .row > .column > div > div:first-child {
  font-weight: bold;
  width: 220px;
  height: 30px;
  align-items: center;
  text-align: right;
  margin-right: 5px;
}

.order-info-tab > .row > .column > .input > div:first-child {
  margin-top: 5px;
}

.order-info-tab > .row > .column > .input > .input {
  height: 32px;
}

.order-info-tab > .row > .column > .input > .input > input {
  font-size: 11px;
  padding: 4px 8px;
}

.order-info-tab > .row > .column > .single-line > div:last-child {
  flex: 1;
}

.order-info-tab > .row > .column > div > form.ui.form {
  min-width: 210px;
  min-height: 30px;
  margin: 10px;
  margin-top: 0;
}

.order-info-tab > .row > .column > div > form.ui.form > textarea {
  width: 100%;
  height: 100%;
}

.order-info-tab > .row > .column > .notas-pedido {
  flex-direction: column;
}

.order-info-tab > .row > .column > .notas-pedido > div:first-child {
  text-align: left;
  width: 210px;
}

.order-info-tab > .row > .column > .notas-pedido > div:last-child {
  margin: 10px;
  margin-top: 0;
  height: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 8px;
}

.order-info-tab > .row > .column > .notas-pedido > div:last-child > div {
  width: 100%;
  min-height: 50px;
}

.order-info-tab > .row > .column > div:last-child > .linkConfirmationOrderInfoTab {
  width: 200px;
  display: flex;
  align-items: flex-end;
  overflow-x: hidden;
}

#orderDetailsForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

#orderDetailsForm > div > form > div:nth-child(odd) {
  align-items: center;
  display: flex;
  margin: 0px 5px;
  font-size: 14px;
  font-weight: bold;
}

#orderDetailsForm > div > form > .input {
  width: 90px;
}

#orderDetailsForm > div > form {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

#orderDetailsForm > div > form > .actions {
  transform: translateX(10px);
  width: 300px;
  display: flex;
  justify-content: right;
}

#orderDetailsForm > div > form > .actions > button {
  margin-left: 10px;
  width: 135px;
  height: 32px;
  padding: 0px;
  font-weight: bold;
  font-size: 12px;
}

#orderDetailsForm > div > form > .row > .remission {
  width: 125px;
  margin-left: 15px;
}

.navixy-container-button {
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-buttons-container {
  width: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-right: 20px;
  height: 30px;
  margin-top: 10px;
  position: relative;
  z-index: 0;
}

.download-buttons-container > div {
  display: flex;
  flex-direction: column;
}

.download-buttons-container > div > .downloadFileButton {
  display: block;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  align-self: center;
  margin-bottom: 5px;
  cursor: pointer;
  z-index: 5;
  border: none;
  width: 140px;
  height: 33px;
  line-height: 33px;
  font-size: 12px;
  font-weight: 700;
}

.download-buttons-container > div > .downloadFileButton:hover {
  background-color: #003f66 !important;
}

.download-buttons-container > div > .downloadFileButton > i {
  font-size: 18px;
}

#lastChecklistToDownload {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

#lastChecklistToDownload > button {
  text-decoration-line: underline;
  font-size: 12px;
  width: 80px;
  padding: 10px;
  margin-left: 20px;
}

.orders-in-transit-incidences-tab > div > .table-responsive {
  min-height: 70px;
}

.orders-in-transit-incidences-tab > form {
  display: flex;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.orders-in-transit-incidences-tab > form > .col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.orders-in-transit-incidences-tab > form > .col > .form-field {
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 5px;
}

.orders-in-transit-incidences-tab > form > .col > .form-field > label {
  width: 80px;
  text-align: right;
  margin: 0px;
  margin-right: 5px;
}

.orders-in-transit-incidences-tab > form > .col > .form-field.text-area > label {
  align-self: start;
}

.orders-in-transit-incidences-tab > form > .col > .form-field > label + * {
  flex: 1;
}

.orders-in-transit-incidences-tab > form > .col > div:not(.form-field) {
  display: flex;
  align-items: center;
  min-height: 34px;
  justify-content: space-between;
}

.orders-in-transit-incidences-tab > form > .col > div > .form-field {
  display: flex;
  align-items: center;
}

.orders-in-transit-incidences-tab > form > .col > div > .form-field > label {
  margin: 0px;
  margin-right: 5px;
}

.orders-in-transit-incidences-tab > form > .col:nth-child(2) > .form-field > label {
  width: 115px;
}

.orders-in-transit-incidences-tab > form > .col:last-child {
  flex: 0;
  padding: 0;
}

.orders-in-transit-incidences-tab > form > .col:last-child > button {
  width: 20px;
}

.orders-in-transit-incidences-tab > form > .col:last-child > button > i {
  transform: translateX(-5px);
}

.orders-in-transit-incidences-tab > .form-actions {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-bottom: 20px;
}

.orders-in-transit-incidences-tab > .form-actions > button {
  font-size: 12px;
}

.orders-in-transit-stay-tab > form {
  display: flex;
}

.orders-in-transit-stay-tab > form > .col {
  display: flex;
  flex-direction: column;
}

.orders-in-transit-stay-tab > form > .col > .form-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.orders-in-transit-stay-tab > form > .col > .form-field > label:first-child {
  margin: 0;
  margin-right: 5px;
  text-align: right;
  width: 130px;
  font-weight: bold;
}

.orders-in-transit-stay-tab > form > .col > .form-field.multiline > label:first-child {
  align-self: flex-start;
}

.orders-in-transit-stay-tab > form > .col > .form-field > :not(label:first-child),
.orders-in-transit-stay-tab > form > .col > .form-field > .input.ui {
  margin: 0;
  flex: 1;
  font-size: 11px;
}

.orders-in-transit-stay-tab > form > .col > .form-field > .input.ui {
  width: 50px;
  flex: 0;
  height: 25px;
  margin-right: 5px;
}

.orders-in-transit-stay-tab > form > .col > .form-field.multiline > textarea {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.orders-in-transit-stay-tab > form > .col > .form-field.multiline > textarea:focus {
  border-color: #85b7d9;
  outline: none;
}

.orders-in-transit-stay-tab > .actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.orders-in-transit-stay-tab > .actions > button {
  font-size: 12px;
}

.orders-in-transit-stay-tab > .actions > input[type="file"] {
  display: none;
}

@media (max-width: 1255px) {
  .download-buttons-container {
    justify-content: center;
  }

  .download-buttons-container > div {
    flex-direction: row;
  }

  .download-buttons-container > div > a {
    margin-right: 10px;
  }
}

@media (max-width: 1000px) {
  .orders-in-transit-incidences-tab > form {
    flex-direction: column;
  }

  .orders-in-transit-stay-tab > form {
    flex-direction: column;
  }
}
