.change-units-screen-container > .plants {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 24px 0;
}

.change-units-screen-container > .plants label {
  margin-right: 12px;
  margin-bottom: 0;
  font-size: 14px;
}

.change-units-screen-container > .plants > .c-plants-dropdown {
  font-size: 12px;
}

.change-units-screen-container > .table {
  height: calc(100vh - 200px);
  overflow: auto;
  display: flex;
}

.fade.change-units-modal.modal.show > .modal-dialog > .modal-content {
  width: 550px;
}

.fade.change-units-modal.modal.show > .modal-dialog > .modal-content >
  .modal-body > .container > .evidence-history {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.fade.change-units-modal.modal.show > .modal-dialog > .modal-content >
  .modal-body > .container > .evidence-history > .evidence-history-table {
  display: flex;
  overflow: auto;
  width: 450px;
  max-height: 250px;
}

.fade.change-units-modal.modal.show > .modal-dialog > .modal-content > 
  .modal-body > .container > .evidence-history > .no-evidence-history {
  font-weight: bold;
  font-size: 14px;
  margin-top: 8px;
}

.change-units-modal .modal-dialog .modal-body .container .row .c-tab-options label {
  font-size: 12px;
}

.change-units-modal .change-base-form > div {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  margin-bottom: 10px;
}

.change-units-modal .change-base-form > div > .input {
  width: 100%;
}

.change-units-modal .change-base-form > div > .label-disabled {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 2px;
  width: 120px;
  height: 100%;
  margin-bottom: 0;
  background-color: #8d8d8d;
  color: white;
}

.change-units-modal .change-base-form > div > .input > input {
  flex: 1;
}

.change-units-modal .change-base-form > .dropdown-container {
  display: flex;
  align-items: center;
}

.change-units-modal .change-base-form > .dropdown-container label {
  width: 125px;
  margin: 0;
}

.change-units-modal .change-base-form > .dropdown-container > .dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2 ;
  border-radius: 4px;
}

.change-units-modal .change-base-form .actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  height: 36px;
}

.change-units-modal .change-status-form > div {
  display: flex;
  justify-content: right;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.change-units-modal .change-status-form > div > .input,
.change-units-modal .change-status-form > .dropdown-container > .dropdown,
.change-units-modal .change-status-form > div > .notes-input-box {
  width: calc(100% - 180px);
}

.change-units-modal .change-status-form > div > .change-status-label,
.change-units-modal .change-status-form > div > .change-status-label-note {
  margin-right: 5px;
  margin-bottom: 0px;
}

.change-units-modal .change-status-form > div > .change-status-label-note {
  margin-top: 5px
}

.change-units-modal .change-status-form > .note-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.change-units-modal .change-status-form > .dropdown-container > .dropdown {
  float: right;
  height: 30px;
  padding: 6px;
  border: 1px solid #f2f2f2 ;
  border-radius: 4px;
}

.change-units-modal .change-status-form .actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  height: 36px;
}
