.screen-drivers {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  overflow: hidden;
}

.screen-drivers  sup {
  color: red;
}

.screen-drivers > span.title-text {
  font-size: 3rem;
  font-weight: 400;
}

.screen-drivers > .navixy-config-metter {
  font-size: 12px;
  padding: 5px;
  margin-bottom: 5px;
  width: 40%;
  max-width: 400px;
  color: black;
  font-weight: bold;
}

.screen-drivers > .body-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
}

.screen-drivers > .body-container > .form-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
}

.screen-drivers > .body-container > .form-container > .form-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
}

.screen-drivers > .body-container > .form-container > .form-line > .form-style {
  display: flex;
  width: 65%;
  margin-left: 5%;
  justify-content: space-between;
  flex-direction: column;
}

.screen-drivers > .body-container > .form-container > .form-line > .form-style > .ui.mini.form > .form-line {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.screen-drivers > .body-container > .form-container > .form-line > .form-style > .ui.mini.form > .form-line > span {
  margin-right: 10px;
  min-width: 100px;
  text-align: right;
}

.screen-drivers > .body-container > .form-container > .form-line > .form-style > .ui.mini.form > .form-line > *:last-child {
  flex: 1;
}

.screen-drivers > .body-container > .form-container > .form-line > .subtitle-text-status {
  font-size: 12px;
  padding-right: 5px;
}

.screen-drivers > .body-container > .form-container > .form-line > .subtitle-text {
  width: 45%;
  font-size: 12px;
  text-align: right;
  padding-right: 5px;
}

.screen-drivers > .body-container > .form-container > .form-line > .image-container {
  display: flex;
  width: 30%;
  height: 200px;
  margin-bottom: 15px;
  flex-direction: column;
}

.screen-drivers > .body-container > .form-container > .form-line > .image-container > .c-image-container img {
  width: 100%;
}

.screen-drivers > .body-container > .form-container > .buttons-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  transform: translateX(10px);
}

.screen-drivers > .body-container > .form-container > .buttons-container > .button-action {
  margin-right: 10px;
  font-size: 12px;
}

.screen-drivers > .body-container > .table-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 10px;
}

.screen-drivers > .body-container > .table-container > .drivers-excel-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.screen-drivers > .body-container > .table-container > .table {
  display: flex;
  overflow: auto;
}
