.screen-check-unit-status {
  display: flex;
  height: 90%;
  width: 100%;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.screen-check-unit-status > .form-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 60%;
  border-radius: 5px;
  align-items: center;
}

.screen-check-unit-status > .form-container > .header-title {
  display: flex;
  width: 100%;
  justify-content: center;
}

.screen-check-unit-status > .form-container > .header-title > .title-text {
  font-weight: 400;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 20px;
}

.screen-check-unit-status > .form-container > .form-line {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
}

.screen-check-unit-status > .form-container > .form-line > .label-key {
  font-size: 1rem;
  width: 25%;
  text-align: right;
  padding-right: 15px;
  align-self: center;
}

.screen-check-unit-status > .form-container > .form-line > .ui.fluid.search.selection.dropdown {
  width: 75%;
  min-height: auto;
  white-space: nowrap;
  font-size: 1.1rem;
}

.screen-check-unit-status > .form-container > .form-line > .fake-input {
  border-bottom: solid 2px rgb(192,192,192,1);
  flex: 1;
  min-height: 30px;
  transform: translatey(3px);
}

.screen-check-unit-status > .form-container > footer {
  display: flex;
  width: 70%;
  justify-content: flex-end;
  margin-top: 10px;
}
