.screen-incidences-logs {
  display: flex;
  flex-direction: column;
}

.screen-incidences-logs > .content {
  display: flex;
  flex-direction: column;
}

.screen-incidences-logs > .content > .search-bar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.screen-incidences-logs > .content > .search-bar > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.screen-incidences-logs > .content > .search-bar > .form-field > label {
  margin: 0;
  margin-right: 5px;
}

.screen-incidences-logs > .content > .search-bar > .form-field > label + * {
  font-size: 10px;
}

.screen-incidences-logs > .content > .search-bar > .form-field:first-child > label + * {
  width: 300px;
}

.screen-incidences-logs > .content > .search-bar > .form-field.date > .react-datepicker-wrapper > .react-datepicker__input-container > input{
  height: 30px;
  border-radius: 3px;
  border: 1px solid #ddd;
  width: 150px;
  padding: 0px 10px;
}

.screen-incidences-logs > .content > .search-bar > button {
  font-size: 12px;
}

.screen-incidences-logs > .content > .table > div > .table-responsive {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.incidences-log-modal > h3 {
  border-bottom: 1px solid silver;
  padding-bottom: 15px;
}

.incidences-log-modal > .row {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: flex-end;
}

.incidences-log-modal > .row > .col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.incidences-log-modal > .row > .col > .field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.incidences-log-modal > .row > .col > .field > label:first-child {
  font-weight: bold;
  font-size: 14px;
  margin-right: 5px;
}

.incidences-log-modal > .row > .col > .field > label:last-child {
  flex: 1;
  transform: translateY(0.6px);
}

.incidences-log-modal > .row > .col > .field > label:first-child {
  width: 180px;
  text-align: right;
}

.incidences-log-modal > .row > .col:last-child > .field > label:first-child {
  width: 240px;
}

.incidences-log-modal > .row > .col:last-child > .field {
  height: 40px;
}

.incidences-log-modal > .row > .col > .field.textarea {
  height: auto;
  max-height: 80px;
  align-items: flex-start;
}

.incidences-log-modal > .row > .col > .field.textarea > label:first-child {
  margin-top: 4px;
}

.incidences-log-modal > .row > .col > .field.textarea > label:last-child {
  max-height: 80px;
  overflow-y: auto;
  padding: 5px 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.incidences-log-modal > .row > .col > .field > label + .input,
.incidences-log-modal > .row > .col > .field > label + textarea {
  font-size: 12px;
  transform: translatey(-3px);
  flex: 1;
  max-height: 80px;
}

.incidences-log-modal > .row > .col > .field > label + textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 8px;
}

.incidences-log-modal > .row > .col > .field.check > label:last-child {
  border: 1px solid #ddd;
  flex: none;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .incidences-log-modal > .row > .col > .field > label:first-child {
    width: 240px;
  }
}

@media only screen and (max-width: 1000px) {
  .incidences-log-modal > .row > .col > .field {
    flex-direction: column;
    height: auto;
    flex: 1;
    min-width: 300px;
  }

  .incidences-log-modal > .row > .col > .field > label:first-child,
  .incidences-log-modal > .row > .col:last-child > .field > label:first-child {
    text-align: left;
    width: 100%;
  }

  .incidences-log-modal > .row > .col > .field > label:last-child,
  .incidences-log-modal > .row > .col > .field > label + .input,
  .incidences-log-modal > .row > .col > .field > label + textarea {
    text-align: left;
    width: 100%;
  }
}
