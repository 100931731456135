.c-image-container > #c-image-container-input {
  display: none;
}

.vehicles-screen-form > .form-section-one .c-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.vehicles-screen-form > .form-section-one .c-image-container input {
  display: none;
}

.vehicles-screen-form > .form-section-one .c-image-container img {
  width: 100%;
}

.vehicles-screen-form > .form-section-one .c-image-container .c-image-container-loader {
  height: 205px;
  display: flex;
  align-items: center;
}
