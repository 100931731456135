.screen-order-assignation {
  display: flex;
  height: calc(100vh - 170px);
  overflow: hidden;
}

.screen-order-assignation > .search {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 30px;
}

.screen-order-assignation > .search > .filter-label {
  font-weight: bold;
}

.screen-order-assignation > .search > .search-bar {
  display: flex;
  flex-direction: row;
}

.screen-order-assignation > .search > .search-bar > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.screen-order-assignation > .search > .search-bar > .form-field > label {
  margin: 0;
  margin-right: 5px;
  font-weight: bold;
}

.screen-order-assignation > .search > .search-bar > .form-field > label + * {
  font-size: 10px;
  width: 300px;
}

.screen-order-assignation > .search > .search-bar > button.ui.primary {
  font-size: 12px;
  width: 70px;
  margin-right: 10px;
}

.screen-order-assignation > .search > .search-bar > .form-field > .c-plants-dropdown,
.screen-order-assignation > .search > .search-bar > .form-field > .chunks-dropdown {
  width: 300px;
}

.screen-order-assignation > .search > .stats-info {
  margin: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.screen-order-assignation > .search > div > .table-responsive {
  height: calc(100vh - 300px);
}

.screen-order-assignation > form {
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.screen-order-assignation > form > .form-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.screen-order-assignation > form > .form-field > label,
.screen-order-assignation > form > .double-column > .form-field > label {
  width: 170px;
  margin: 0;
  text-align: right;
  margin-right: 10px;
}

.screen-order-assignation > form > .form-field > label + * {
  flex: 1;
}

.screen-order-assignation > form > .form-field > button {
  font-size: 12px;
}

.screen-order-assignation > form > .double-column > .form-field > .ui.checkbox,
.screen-order-assignation > form > .form-field > .ui.checkbox {
  height: 25px;
  margin-top: 8px;
}

.screen-order-assignation > form > .double-column {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.screen-order-assignation > form > .double-column > .form-field {
  display: flex;
  align-items: center;
}

.screen-order-assignation > form > .double-column > .form-field > .warning-icon {
  margin-right: 10px;
}

.screen-order-assignation > form > .double-column > .form-field:last-child {
  align-self: end;
  display: flex;
  align-items: center;
}

.screen-order-assignation > form > .form-field.text-area {
  align-items: flex-start;
}

.screen-order-assignation.products-table-modal > .modal-dialog {
  width: 0;
}

.screen-order-assignation.products-table-modal > .modal-dialog > .modal-content {
  width: 700px;
  transform: translateX(-50%);
}

.screen-order-assignation > form > .actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.screen-order-assignation > form > .actions > button {
  font-size: 11px;
}

.unit-without-gps-modal-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.unit-without-gps-modal-field > label {
  margin: 0;
}

.unit-without-gps-modal-field > label + * {
  flex: 1;
}

@media only screen and (max-width: 1600px) {
  .screen-order-assignation > .search {
    max-width: 900px;
  }
}

@media only screen and (max-width: 1300px) {
  .screen-order-assignation > .search > .search-bar {
    flex-direction: column;
  }

  .screen-order-assignation > .search > .search-bar > button {
    align-self: flex-end;
  }
}
