.screen-plants-crud > .content {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.screen-plants-crud > .content > .form {
  width: 50%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

.screen-plants-crud > .content > .form > .double-column {
  display: flex;
  justify-content: space-between;
  max-width: 670px;
}

.screen-plants-crud > .content > .form > .double-column > div > .field > .email-label {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-bottom: 5px;
}

.screen-plants-crud > .content > .form > .double-column > div > .field > .emails-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
}

.screen-plants-crud > .content > .form > .double-column > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 335px;
}

.screen-plants-crud > .content > .form > .double-column > div > .field,
.screen-plants-crud > .content > .form > .single-column > .field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.screen-plants-crud > .content > .form > .double-column > div > .field > label,
.screen-plants-crud > .content > .form > .single-column > .field > label {
  width: 170px;
  margin-bottom: 0;
  margin-right: 5px;
  text-align: end;
  font-weight: normal;
}

.screen-plants-crud > .content > .form > .double-column > div > .field > div,
.screen-plants-crud > .content > .form > .single-column > .field > div {
  flex: 1;
  font-size: 12px;
}

.screen-plants-crud > .content > .form > .double-column > div > .field > .radio-group,
.screen-plants-crud > .content > .form > .single-column > .field > .radio-group {
  display: flex;
  align-items: center;
}

.screen-plants-crud > .content > .form > .double-column > div > .field > .radio-group > div > label,
.screen-plants-crud > .content > .form > .single-column > .field > .radio-group > div > label {
  margin-bottom: 0;
  font-size: 12px;
}

.screen-plants-crud > .content > .form > .single-column > .field:last-child {
  align-items: flex-start;
}

.screen-plants-crud > .content > .form > .single-column > .field > div > textarea {
  min-height: 60px;
  width: 100%;
}

.screen-plants-crud > .content > .form > .single-column {
  max-width: 670px;
  border-bottom: 2px solid var(--primary);
}

.screen-plants-crud > .content > .table-container {
  width: 50%;
}

.screen-plants-crud > .content > .table-container > .configured-plants,
.screen-plants-crud > .content > .form > .yard-form > .field > .configured-yards {
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: 4px;
  background-color: var(--configuration-bgn);
}

.screen-plants-crud > .content > .table-container > .configured-plants > label,
.screen-plants-crud > .content > .form > .yard-form > .field > .configured-yards > label {
  margin-bottom: 0;
  padding: 5px 10px;
}

.screen-plants-crud > .content > .table-container > .table {
  display: flex;
  overflow: auto;
  height: calc(100vh - 182px);
  margin-top: 5px;
}

.screen-plants-crud > .content > .form > .yard-form {
  margin-top: 8px;
  max-width: 670px;
}

.screen-plants-crud > .content > .form > .yard-form > .field {
  display: flex;
  align-items: center;
}

.screen-plants-crud > .content > .form > .yard-form > .field:first-child > label {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: normal;
}

.screen-plants-crud > .content > .form > .yard-form > .field:first-child > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  flex: 1;
}

.screen-plants-crud > .content > .form > .yard-form > .field:first-child > div > .dropdown {
  margin-right: 12px;
}

.screen-plants-crud > .content > .form > .yard-form > .field:last-child {
  justify-content: space-between;
  margin-top: 12px;
}

.screen-plants-crud > .content > .form > .yard-form > .field > .button {
  font-size: 12px;
}

.screen-plants-crud > .content > .form > .yards-table {
  display: flex;
  overflow: auto;
  max-height: 200px;
  margin-top: 12px;
  max-width: 670px;
}

.screen-plants-crud > .content > .form > .yards-table #delete-yard-button {
  font-weight: bold;
  margin: 0;
  cursor: pointer;
}

.screen-plants-crud > .content > .form > .actions {
  max-width: 670px;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.screen-plants-crud > .content > .form > .actions > .button {
  font-size: 12px;
}

@media (max-width: 1400px) {
  .screen-plants-crud > .content > .form > .double-column {
    flex-direction: column;
  }

  .screen-plants-crud > .content > .form > .double-column > div {
    width: 100%;
  }

  .screen-plants-crud > .content > .form {
    margin-right: 32px;
  }
}

@media (max-width: 900px) {
  .screen-plants-crud > .content {
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 170px);
  }

  .screen-plants-crud > .content > .form {
    height: 60%;
    width: 100%;
    margin: 0 auto 32px;
  }

  .screen-plants-crud > .content > .form > .double-column,
  .screen-plants-crud > .content > .form > .single-column,
  .screen-plants-crud > .content > .form > .actions {
    margin: 0 auto;
  }

  .screen-plants-crud > .content > .form > .yard-form,
  .screen-plants-crud > .content > .form > .yards-table {
    margin: 12px auto 0;
  }

  .screen-plants-crud > .content > .table-container {
    height: 40%;
    width: 100%;
  }

  .screen-plants-crud > .content > .table-container > .table {
    height: 100%;
  }
}
