.screen-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F8FAFC;
  font-family: Helvetica, sans-serif;
  height: 100vh;
}

.screen-login > .inside-container {
  background-image: url('../images/background-login.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  width: 380px;
  height: 550px;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.screen-login > .inside-container > .img-container {
  width: 90%;
  margin-bottom: 170px;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 8px;
  border-radius: 5px;
}

.screen-login > .inside-container > .form-container {
  margin-top: 10px;
  min-width: 180px;
  width: 90%;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.screen-login > .inside-container > .form-container > .ui.form > div > .input-container {
  border: 0px;
  border-radius: 5px;
  border: 1px solid gray;
  margin-top: 4%;
  margin-bottom: 5%;
}

.screen-login > .inside-container > .form-container > .ui.form > .ui.fluid.primary.button {
  box-shadow: 0px 1px 8px 1px rgb(0, 0, 0);
}

.screen-login > .inside-container > .form-container > .ui.form > .field > span{
  color: white;
  font-size: 1.15rem;
}

.screen-login > footer {
  background-color: #F8FAFC;
  position: fixed;
  bottom: 0;
  width: 100vw;
  justify-content: left;
  padding: 10px;
}

.screen-login > footer > .version-text {
  font-size: 0.8rem;
  margin: 10px;
}

.screen-login > footer > .powered-container {
  display: flex;
  float: right;
  justify-content: right;
}

.screen-login > footer > .powered-container > .powered-logo {
  width: 100px;
  margin-left: 10px;
  float: right;
}

@media only screen and (max-width: 600px) {
  .screen-login > .inside-container {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
    padding-bottom: 110px;
  }
}
