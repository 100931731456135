.screen-order-confirmation {
  width: 60%;
  margin: 0 auto;
}
.screen-order-confirmation > .btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.screen-order-confirmation > div > section {
  text-align: center;
}

.screen-order-confirmation > div > .content {
  margin: 10px 0;
}

.screen-order-confirmation > div > .content > .single-row,
.screen-order-confirmation > div > .content > .double-row {
  display: flex;
  align-items: center;
}

.screen-order-confirmation > div > .content > .single-row > label:first-child,
.screen-order-confirmation > div > .content > .double-row > div > label:first-child {
  width: 150px;
  text-align: right;
  margin-right: 10px;
}

.screen-order-confirmation > div > .content > .double-row > div:last-child > label:first-child {
  width: 180px;
}

.screen-order-confirmation > div > .content > .single-row > label:last-child,
.screen-order-confirmation > div > .content > .double-row > div > label:last-child {
  flex: 1;
  font-weight: bold;
  color: rgba(0, 0, 0, .6);
  background-color: #e8e8e8;
  padding: 4px 4px 4px 12px;
  border-radius: 3px;
  height: 25px;
  width: 100%;
}

.screen-order-confirmation > div > .content > .double-row > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}


@media (max-width: 1400px) {
  .screen-order-confirmation {
    width: 70%;
  }
}

@media (max-width: 1200px) {
  .screen-order-confirmation {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .screen-order-confirmation > div > .content > .double-row {
    flex-direction: column;
  }
  .screen-order-confirmation > div > .content > .single-row > label:first-child,
  .screen-order-confirmation > div > .content > .double-row > div > label:first-child {
    width: 180px;
  }
}

@media (max-width: 500px) {
  .screen-order-confirmation {
    width: 95%;
  }

  .screen-order-confirmation > div > .content > .single-row,
  .screen-order-confirmation > div > .content > .double-row > div {
    flex-direction: column;
    text-align: center;
  }

  .screen-order-confirmation > div > .content > .single-row > label:first-child,
  .screen-order-confirmation > div > .content > .double-row > div > label:first-child {
    text-align: center;
  }
}
