.screen-initial-inventory > .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 35px;
}

.screen-initial-inventory > .content div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 350px;
  margin: auto;
}

.screen-initial-inventory > .content label {
  height: 36px;
  margin: 0px;
  margin-top: 5px;
  text-align: center;
  align-self: center;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
}

.screen-initial-inventory > .content > .plant-container > .ui.form > #c-plants-dropdown {
  font-size: 13px;
}

.screen-initial-inventory > .content > .save-button {
  margin-top: 20px;
}
