.screen-order-crud > .content {
  display: flex;
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.screen-order-crud > .content > .list {
  width: 45%;
}

.screen-order-crud > .content > .list > div > .table-responsive {
  height: calc(100vh - 270px);
}

.screen-order-crud > .content > .list > div > .table-responsive > table > tbody > tr {
  height: 10px;
  margin: 0;
  padding: 0px;
}

.screen-order-crud > .content > .list > .search-bar > form {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.screen-order-crud > .content > .list > .search-bar > form > label {
  margin: 0px;
}

.screen-order-crud > .content > .list > .search-bar > form > .dropdown {
  margin: 0px 10px;
}

.screen-order-crud > .content > .list > .search-bar > form > button {
  height: 30px;
  font-size: 12px;
}

.screen-order-crud > .content > form {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 50px;
  margin-top: 10px;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

.screen-order-crud > .content > form > .row {
  display: flex;
  flex-direction: row;
}

.screen-order-crud > .content > form > .row > .col {
  display: flex;
  flex-direction: column;
}

.screen-order-crud > .content > form > .row > .col > .form-field,
.screen-order-crud > .content > form > .form-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.screen-order-crud > .content > form > .form-field {
  align-items: flex-start;
}

.screen-order-crud > .content > form > .row > .col > .form-field > label,
.screen-order-crud > .content > form > .form-field > label {
  min-width: 160px;
  text-align: right;
  margin-right: 5px;
  margin-bottom: 0px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > label + *,
.screen-order-crud > .content > form > .form-field > label + * {
  flex: 1;
  font-size: 10px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > label + * {
  width: 150px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > label + * {
  max-width: 330px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > .radio-group,
.screen-order-crud > .content > form > .row > .col > .form-field > .check-container {
  display: flex;
  align-items: center;
  height: 30px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > .radio-group > .radio {
  font-size: 10px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > .radio-group > .radio > label {
  margin: 0px;
}

.screen-order-crud > .content > form > .row > .col > .form-field > .check-container > button {
  height: 30px;
  font-size: 12px;
  margin-left: 5px;
}

.screen-order-crud > .content > form > .form-actions {
  display: flex;
  justify-content: right;
}

.screen-order-crud > .content > form > .form-actions > button {
  font-size: 12px;
  height: 30px;
}

.screen-order-crud > .content > form > h6 {
  border-bottom: 2px solid var(--primary);
  padding: 5px;
  font-weight: bold;
}

.screen-order-crud > .content > form > footer {
  border-top: 2px solid var(--primary);
  display: flex;
  justify-content: space-between;
}

.screen-order-crud > .content > form > footer > h6 {
  padding: 5px;
  font-weight: bold;
}

.screen-order-crud > .content > form > .row > .col > .form-field > .quantity-field {
  display: flex;
}

.screen-order-crud > .content > form > .row > .col > .form-field > .quantity-field > * {
  flex: 1;
}

.screen-order-crud > .content > form > .row > .col > .form-actions {
  display: flex;
  justify-content: flex-end;
}

.screen-order-crud > .content > form > .row > .col > .form-actions  > button {
  font-size: 12px;
  height: 30px;
  width: 100px;
  background-color: var(--secondary);
  color: white;
  margin-bottom: 5px;
}

.screen-order-crud > .content > form > div > .table-responsive {
  height: 150px;
}

.client-unit-modal-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.client-unit-modal-field > label + * {
  flex: 1
}

@media only screen and (max-width: 1600px) {
  .screen-order-crud > .content > form > .row > .col > .form-field > label,
  .screen-order-crud > .content > form > .form-field > label {
    min-width: 80px;
  }
}

@media only screen and (max-width: 1250px) {
  .screen-order-crud > .content > form > .row > .col > .form-field > label,
  .screen-order-crud > .content > form > .form-field > label {
    min-width: 160px;
  }
}
