.screen-no-availability-crud {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.screen-no-availability-crud > .content {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  height: 95%;
  padding: 10px;
  justify-content: space-between;
}

.screen-no-availability-crud > .content > .table-no-availability > div > .table-responsive {
  height: calc(100vh - 225px);
}

.screen-no-availability-crud > .content > form {
  width: calc(50% - 40px);
  margin-right: 40px;
}

.screen-no-availability-crud > .content > form > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.screen-no-availability-crud > .content > form > .form-field > .ui.input {
  min-width: 150px;
}

.screen-no-availability-crud > .content > form > .form-field > label {
  margin: 0;
  margin-right: 5px;
  width: 150px;
  text-align: right;
}

.screen-no-availability-crud > .content > form > .form-field > label + * {
  font-size: 10px;
  margin: 0;
  flex: 1;
}

.screen-no-availability-crud > .content > form > .form-field > .radio-group {
  align-items: center;
  display: flex;
}

.screen-no-availability-crud > .content > form > .form-field > .radio-group > .ui.radio {
  height: 18px;
}

.screen-no-availability-crud > .content > form > button {
  align-items: center;
}

.screen-no-availability-crud > .content > .table-no-availability {
  width: 50%;
}

.screen-no-availability-crud > .content > form > .form-actions {
  display: flex;
  justify-content: right;
}

.screen-no-availability-crud  > .content > form > .form-actions > button {
  font-size: 12px;
  height: 30px;
}
