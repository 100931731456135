.screen-users {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 12px;
  height: calc(100vh - 170px);
}

.screen-users > .form {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-right: 16px;
  overflow-y: auto;
}

.screen-users > .form > .image-section {
  width: 250px;
}

.screen-users > .form > .image-section > .c-image-container img {
  width: 100%;
}

.screen-users > .form > .content {
  padding: 0 12px;
  min-width: 350px;
  max-width: 550px;
}

.screen-users > .form > .content > div {
  margin-bottom: 6px;
}

.screen-users > .form > .content > div:last-child {
  margin-top: 22px;
  justify-content: flex-end;
}

.screen-users > .form > .content > div,
.screen-users > .form > .content > div > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.screen-users > .form > .content > div > div {
  flex-wrap: wrap;
}

.screen-users > .form > .content > div > .options > div {
  display: flex;
  margin-right: 10px;
}

.screen-users > .form > .content > div > div > div > label {
  margin: 0 6px 0 0;
}

.screen-users > .form > .content > div > label {
  margin: 0 6px 0 0;
  width: 120px;
  text-align: end;
}

.screen-users > .form > .content > div > .multi-line-input {
  align-self: flex-start;
  margin-top: 6px;
}

.screen-users > .form > .content > div > div > label {
  margin: 0 6px 0 0;
}

.screen-users > .form > .content > div > .input {
flex: 1;
}

.screen-users > .form > .content > div > .button {
  font-size: 12px;
  margin-right: 0;
  margin-left: 10px;
}

.screen-users > .table {
  display: flex;
  width: 40%;
  height: calc(90vh - 70px);
  overflow: auto;
}

@media (max-width: 1100px) {
  .screen-users > .form {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 30px;
  }

  .screen-users > .form > .content {
    margin-top: 18px;
    height: 250px;
  }
}

@media (max-width: 600px) {
  .screen-users {
    flex-direction: column;
    height: calc(100vh - 120px);
  }

  .screen-users > .form {
    width: 100%;
    margin-bottom: 30px;
  }

  .screen-users > .table {
    width: 100%;
  }
}
