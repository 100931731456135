.header-orders-todays-container {
  color: rgb(0, 42, 68);
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: space-between;
}

.header-orders-todays-container .header-orders-todays-content {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.header-orders-todays-container .header-orders-todays-content:first-child > div {
  margin-right: 10px;
}

.header-orders-todays-container .header-orders-todays-content > div {
  border-radius: 12px;
} 

.header-orders-todays-container .header-orders-todays-content img {
  width: 80%;
  margin: auto;
}

.header-orders-todays-container .pages-count {
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 500;
  text-decoration-line: underline;
}

.table-orders-todays-monitor {
  display: flex;
  width: 100%;
  height: 80%;
}

.table-orders-todays-monitor .not-orders-todays {
  width: 100%; 
  display: flex; 
  flex: 1; 
  justify-content: center; 
  align-items: center;
}

.table-orders-todays-monitor .not-orders-todays .not-orders-todays-content {
  font-size: 3rem; 
  font-weight: bold;
}

.table-orders-todays-monitor .highlighted-info {
  font-weight: 600; 
  font-size: 1.2rem;
}

.table-orders-todays-monitor .table > thead > tr {
  text-align: center;
}

.table-orders-todays-monitor table > thead > tr > th {
  font-size: 1.5rem !important;
}

.table-orders-todays-monitor .table-striped tbody tr:nth-of-type(odd) {
  background-color: #e9e9e9 !important;
}

.table-orders-todays-monitor .table > tbody > tr > td:nth-child(1),
.table-orders-todays-monitor .table > tbody > tr > td:nth-child(2),
.table-orders-todays-monitor .table > tbody > tr > td:nth-child(8),
.table-orders-todays-monitor .table > tbody > tr > td:nth-child(13) {
  font-weight: 600;
  font-size: 1.4rem !important; 
}

.table-orders-todays-monitor .table > tbody > tr > td:nth-child(2),
.table-orders-todays-monitor .table > tbody > tr > td:nth-child(3) {
  text-align: start;
}

.table-orders-todays-monitor table tbody tr td, 
.table-orders-todays-monitor table tbody tr th {
  text-align: center;
}

.container-progress-bar-otm {
  background-color: #e1e1e1;
  border-radius: 12px;
}

.container-progress-bar-otm > .ui.progress > .bar {
  min-width: 0;
  height: 100%;
  background-color: rgb(0, 42, 68);
  border-radius: 12px;
  margin: 0;
  transition: all ease;
}
