.mainContainerOrderInf .formInputsOrderInformation div {
  display: contents;
}

.mainContainerOrderInf .formInputsOrderInformation div div {
  display: contents;
  display: inline-block;
  margin-bottom: 0;
}

@media (max-width: 800px) {
  .mainContainerOrderInf .formContainerOrderInf {
    width: 80% !important;
  }
}

@media (max-width: 650px) {
  .mainContainerOrderInf .formContainerOrderInf {
    width: 95% !important;
  }
  
  .mainContainerOrderInf .formInputsOrderInformation {
    flex-direction: column !important;
    margin-bottom: 0 !important;
  }

  .mainContainerOrderInf .formInputsOrderInformation div {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    justify-content: space-between;
  }
}