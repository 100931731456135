.lastChecklist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lastChecklist span {
  font-weight: bold;
  text-align: right;
  margin-right: 20px;
  width: 24%;
  font-size: 12px;
  padding-right: 0px;
}

.lastChecklist .ui.disabled.input {
  width: 40%;
  margin-right: 10px;
}

.lastChecklist button{
  margin-right: 0 !important;
}