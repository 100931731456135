:root {
  --blue: #007bff;
  --configuration-bgn: #FFF3CD;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #002a44 !important;
  --secondary: #8095a2 !important;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.ui.multiple.search.dropdown > input.search {
  position: fixed !important;
}
.ui.multiple.dropdown > .label {
  font-size: 12px !important;
}

.field > .ui.icon.input > input[placeholder="Contraseña"] {
  border: none;
}

button.ui.fluid.button.main-menu-button {
  border-radius: 0px;
  border: 0px;
  text-align: left;
  background-color: transparent;
  padding-left: 25;
  position: relative;
  transition: all 0.3s ease-in-out;
}

button.ui.fluid.button.main-menu-button:hover {
  color: white;
  background-color: var(--secondary);
}

textarea {
  font-size: 12px !important;
}
div.ui.checked.radio.checkbox {
  margin-left: 0px !important;
}
div.ui.radio.checkbox {
  margin-left: 0px !important;
  margin-right: 5px !important;
}
/* th:hover {
  cursor: pointer;
} */
.ui.modal > .header {
  padding: 10px !important;
  font-size: 16px;
}
.ui.modal > .actions {
  padding: 10px !important;
}
.ui.modal > .content {
  padding: 10px !important;
}
div.react-datepicker-wrapper {
  width: 100% !important;
}

.downloadFileButton:hover {
  background-color: rgb(74, 74, 74) !important;
}

.downloadFileButton.busy {
  background-color: rgb(74, 74, 74) !important;
  cursor: wait !important;
}

.download-buttons-container > div > .downloadFileButton.busy:hover,
.downloadFileButton.busy:hover {
  background-color: rgb(94, 93, 93) !important;
  cursor: wait !important;
}

.modalTitle:hover {
  background-color: rgb(219, 219, 219) !important;
  border-bottom: 3px solid rgb(180, 180, 180);
}

.ui.dimmer {
    background-color: rgba(0,0,0,.55) !important;
}

.react-datepicker__close-icon::after{
  background-color: #1c1c1c !important;
}

.eliminateButtonInTable:hover,
.linkButtonInTable {
  text-decoration: underline;
}

.ui.search.dropdown>.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  }

  .custom-label-styles{
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.5 !important ;
  text-decoration: none !important;
  text-align: center;
  }

.ui.primary.button, .ui.primary.buttons .button {
  background-color: var(--primary) !important;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
  background-color: #003f66 !important;
}

.table > thead > tr > th {
  background-color: var(--primary) !important;
}

.table.items-centered > thead > tr > th,
.table.items-centered > tbody > tr > td {
  text-align: center;
}

.table-hover tbody tr td, .table-hover tbody tr th {
  transition: all 0.25s ease-in-out;
  vertical-align: middle;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: rgb(215, 240, 72, 0.671);
  background-color: var(--secondary);
  color: white;
}

.table-hover tbody tr:hover td > input, .table-hover tbody tr:hover th > input,
.table-hover tbody tr td > input, .table-hover tbody tr th > input {
  color:rgb(74, 74, 74)
}

.ui.mini.form > div > span, .ui.mini.form > div > div > div > span {
  height: 30px;
  line-height: 30px;
}

.checkbox-group {
  padding-top: 6px;
}

.screen-order-finish {
  max-width: 1200px;
  margin-left: 50px;
}

.screen-order-finish > form >.form-field {
  margin-bottom: 10px;
  display: flex;
}

.screen-order-finish > form >.form-field:first-child {
  display: block
}

.screen-order-finish > form >.form-field > span:nth-child(1) {
  display: block;
  width: 160px;
  text-align: right;
}

.screen-order-finish > form >.form-field > span:nth-child(2) {
  font-weight: bold;
  margin-left: 5px;
  border-bottom: 1px solid silver;
}

.modal-body > .container > .row > .col-12 > .ui.breadcrumb.orders-tab-menu > a {
  color: var(--primary) !important;
}

.modal-body > .container > .row > .col-12 > .ui.breadcrumb.orders-tab-menu.selected {
  background-color: var(--primary) !important;
}

.modal-body > .container > .row > .col-12 > .ui.breadcrumb.orders-tab-menu.selected > a {
  color: var(--white) !important;
}

.header__logo-img {
  width: 20px;
  height: 15px;
  margin-right: 3px;
}

.modal-body > .container > .row > .col-12 > .ui.breadcrumb.orders-tab-menu.selected > .section > .header__logo-img {
  fill: white;
}

.header__logo-img--circle {
  transform: scale(0.4);
}

.order-information-version {
  position: fixed;
  bottom: 10px;
  left: 10px;
}

#laodingIcon {
  width: 80px;
  height: 80px;
  animation: ghosting 1.5s infinite;
}

@keyframes ghosting {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@import "./style/index.css"
