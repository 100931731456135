@-webkit-keyframes styled-row {
  from { background-color: inherit; }
  to { background-color: #ffbe3a; }
}
@-moz-keyframes styled-row {
  from { background-color: inherit; }
  to { background-color: #ffbe3a; }
}
@-o-keyframes styled-row {
  from { background-color: inherit; }
  to { background-color: #ffbe3a; }
}
@keyframes styled-row {
  from { background-color: inherit; }
  to { background-color: #ffbe3a; }
}

.change-units-screen-container > .table > div > .table-responsive > table > tbody > tr.styled-row {
  -webkit-animation: styled-row 2s infinite alternate; /* Safari 4+ */
  -moz-animation:    styled-row 2s infinite alternate; /* Fx 5+ */
  -o-animation:      styled-row 2s infinite alternate; /* Opera 12+ */
  animation:         styled-row 2s infinite alternate; /* IE 10+ Chrome */
  padding: 5px;
  font-size: 10px;
  height: 10px;
  overflow: hidden;
}

.change-units-screen-container > .table > div > .table-responsive > table > tbody > tr.normal-row {
  padding: 5px;
  font-size: 10px;
  height: 10px;
  overflow: hidden;
}
