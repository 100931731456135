.screen-order-allocations {
  margin: 10px 0px;
  display: flex;
  height: calc(100% - 30px);
  width: 100%;
  padding: 0;
  justify-content: left;
  flex-direction: column;
  overflow: hidden;
}

.screen-order-allocations > .title-text {
  font-size: 3rem;
  font-weight: 400;
}

.screen-order-allocations > div > .filter-label {
  font-weight: bold; 
  font-size: 14px;
}

.screen-order-allocations > div > .search-bar {
  display: flex;
  width: 60%;
  flex-direction: row;
  align-items: left;
  margin-top: 10px;
  padding-right: 20px;
}

.screen-order-allocations > div > .search-bar > .dropdown-container {
  display: flex;
  flex: 3;
  flex-direction: row;
  align-items: left;
}

.screen-order-allocations > div > .search-bar > .dropdown-container:last-child {
  flex: 1;
  align-items: center;
}

.screen-order-allocations > div > .search-bar > .dropdown-container > .options {
  width: 100%;
  display: flex;
  align-items: center;
}

.screen-order-allocations > div > .search-bar > .dropdown-container > .header-subtitle-text,
.screen-order-allocations > div > .search-bar > .dropdown-container > .options > .header-subtitle-text {
  text-align: right;
  margin-right: 10px;
  font-size: 12px;
  padding-right: 0;
  font-weight: bold;
}

.screen-order-allocations > div > .search-bar > .dropdown-container > .ui.mini.form,
.screen-order-allocations > div > .search-bar > .dropdown-container > .options > .ui.mini.form {
  flex: 1;
  margin-right: 10px;
}

.screen-order-allocations > div > .search-bar > .dropdown-container > .options > .ui.primary.button {
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  margin-right: 10px;
}

.screen-order-allocations > div > .main-content {
  display: flex;
  height: calc(100vh - 234px);
  width: 100%;
  padding-right: 0;
  justify-content: space-between;
}

.screen-order-allocations > div > .main-content > .table-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  align-items: left;
}

.screen-order-allocations > div > .main-content > .table-container > .table-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 5px;
}

.screen-order-allocations > div > .main-content > .table-container > .table-title > div { 
  font-size: 12px;
  font-weight: bold;
}

.screen-order-allocations > div > .main-content > .table-container > .table-inner-container {
  display: flex;
  height: calc(100% - 40px);
  width: 100%;
}

.screen-order-allocations > div > .main-content > .form-container {
  display: flex;
  height: calc(100% + 100px);
  overflow-y: scroll;
  flex-direction: column;
  min-width: 400px;
  width: 33%;
  padding-top: 10px;
  border-radius: 5px;
  align-items: right;
  position: relative;
  top: -100px;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field {
  align-items: right;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field > span {
  text-align: right;
  margin-right: 10px;
  width: 180px;
  font-size: 12px;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field > span + div,
.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field > button {
  flex: 1;
  margin: 0;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field > button {
  background-color: #8095a2;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field > span + div > .ui.fluid.search.selection.dropdown {
  width: calc(100% - 1px);
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > footer {
  flex: 1;
  display: flex;
  justify-content: right;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field.plates > span + div {
  display: flex;
  align-items: center;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field.plates > span + div > div {
  margin-right: 10px;
}

.screen-order-allocations > div > .main-content > .form-container > .ui.mini.form > .field > button.show-button {
  background-color: #b5cc18;
  color: white;
  width: 120px;
  flex: 0;
}

.screen-order-allocations-modal.modal-body > div > div:last-child {
  width: 100%;
}