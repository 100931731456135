.screen-unit-use-report {
  margin-top: 10px;
}

.screen-unit-use-report > .download-button-container {
  display: grid;
  justify-content: flex-end;
}

.screen-unit-use-report > .content {
  display: flex;
  flex-direction: column;
}

.screen-unit-use-report > .content > .search-bar {
  display: flex;
  align-items:center;
  margin-top: 5px;
}

.screen-unit-use-report > .content > .search-bar label {
  font-weight: bold;
  margin-left: 7px;
  margin-right: 5px;
  margin-top: 8px;
}

.screen-unit-use-report > .content > .search-bar > .carrier-container {
  display: flex;
  flex-direction: row;
}

.screen-unit-use-report > .content > .search-bar > .carrier-container .dropdown {
  font-size: 11px;
  height: 31px;
  width: 300px;
  padding: 11px;
}

.screen-unit-use-report > .content > .search-bar > .dates-container .ui.mini.form {
  display: flex;
  flex-direction: row;
}

.screen-unit-use-report > .content > .search-bar > .dates-container .ui.mini.form >
  .react-datepicker-wrapper > .react-datepicker__input-container input {
  width: 170px;
  height: 31px;
}

.screen-unit-use-report > .content > .search-bar > .search-container {
  background-color: var(--primary);
  border-radius: 5px;
  margin-top: -2px;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
}

.screen-unit-use-report > .content > .search-bar > .search-container button {
  font-size: 12px;
}

.screen-unit-use-report > .content > .table {
  margin-top: 5px;
  height: calc(100vh - 240px);
  min-height: 200px;
  overflow: auto;
  display: flex;
}

@media only screen and (max-width: 725px) {
  .screen-unit-use-report > .content > .search-bar {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin: auto;
    margin-top: 20px;
  }

  .screen-unit-use-report > .content > .search-bar > .carrier-container .dropdown {
    width: 300px;
  }

  .screen-unit-use-report > .download-button-container,
  .screen-unit-use-report > .content > .search-bar > .carrier-container,
  .screen-unit-use-report > .content > .search-bar > .dates-container,
  .screen-unit-use-report > .content > .search-bar > .search-container {
    margin-bottom: 5px;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
  }

  .screen-unit-use-report > .content > .table {
    margin-top: 0px;
    height: calc(100vh - 400px);
  }
}
