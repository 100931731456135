.screen-schedule > h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.screen-schedule > .content > .plant-container > #c-plants-dropdown {
  margin-left: 7px;
  margin-top: 10px;
  width: 350px;
  font-size: 13px;
}

.screen-schedule > .content > .schedule-container {
  padding: 10px;
  overflow: auto;
}

.screen-schedule > .content > .schedule-container,
.screen-schedule > .content > .schedule-container > .week-container {
  display: flex;
  flex-direction: row;
}

.screen-schedule > .content > .schedule-container > .side-headers,
.screen-schedule > .content > .schedule-container > .week-container > .day-container {
  display: flex;
  flex-direction: column;
}

.screen-schedule > .content > .schedule-container > div > .ui.header,
.screen-schedule > .content > .schedule-container > .week-container >
.day-container > .header {
  background-color: #002A44;
  color: white;
  font-size: 13px;
  height: 50px;
  margin-bottom: 0px;
  margin-right: 1px;
  text-align: center;
  padding: 17px;
  width: 174px;
}

.screen-schedule > .content > .schedule-container > .side-headers .ui.label {
  font-size: 12px;
  height: 70px;
  margin-left: 0px;
  margin-right: 1px;
  margin-top: 1px;
  padding: 27px;
  text-align: center;
  width: 173px;
}

.screen-schedule > .content > .schedule-container div > div > .ui.input {
  font-size: 9px;
  height: 30px;
  margin-top: 21px;
  margin-bottom: 20px;
  width: 87px;
}

.screen-schedule > .content > .save {
  float: right;
  margin-right: 10px;
  margin-top: 15px;
  padding-bottom: 15px;
}

@media screen and (min-width: 1414px) {
  .screen-schedule > .content > .schedule-container {
    justify-content: center;
  }

  .screen-schedule > .content > .plant-container {
    width: 1414px;
    margin: auto;
  }

  .screen-schedule > .content > .save {
    margin-right: 70px;
  }
}
