.screen-stay-claim h1 {
  padding-top: 10px;
  margin-left: 20px;
  text-align: center;
}
.screen-stay-claim > .content > .ui.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.screen-stay-claim > .content > .ui.form > .section-one,
.screen-stay-claim > .content > .ui.form > .section-two {
  margin: auto;
  margin-top: 15px;
}

.screen-stay-claim > .content > .ui.form > .section-one > .item label,
.screen-stay-claim > .content > .ui.form > .section-two > .item label {
  text-align: right;
  padding: 5px;
  width: 130px;
  font-weight: bold;
}

.screen-stay-claim > .content > .ui.form > .section-one > .item,
.screen-stay-claim > .content > .ui.form > .section-two > .item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.screen-stay-claim > .content > .ui.form > .section-one > .item > .value-order,
.screen-stay-claim > .content > .ui.form > .section-one > .item > .value,
.screen-stay-claim > .content > .ui.form > .section-two > .item > .value {
  background-color: rgba(128, 128, 128, 0.14);
  min-width: 270px;
  width: calc(40vw - 150px);
  min-height: 30px;
  height: auto;
  margin-left: 5px;
  font-weight: normal;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item > div {
  height: 30px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item > .value,
.screen-stay-claim > .content > .ui.form > .section-two > .item textarea,
.screen-stay-claim > .content > .ui.form > .section-two > .item > .ui.search.selection.dropdown {
  min-width: 220px;
  width: 220px;
  padding: 6px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item > .time > .ui.input,
.screen-stay-claim > .content > .ui.form > .section-two > .item > .days > .ui.ui.input {
  height: 30px;
  width: 220px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item > .ui.search.selection.dropdown {
  padding: 11px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item textarea {
  min-height: 55px;
  margin-left: 5px;
  padding: 14px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item > .pdf-button {
  height: 45px;
  width: 220px;
  display: flex;
  align-items: center;
  position: relative;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item > .pdf-button label {
  padding: 5px;
  background-color: #eeeeee;
  text-align: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  margin: 0 0 0 5px;
  width: 100px;
  border-radius: 4px;
}

.screen-stay-claim > .content > .ui.form > .section-two > .item-save {
  float: right;
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .screen-stay-claim > .content > .ui.form {
    flex-direction: column;
  }
}
