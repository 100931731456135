.screen-upload-evidence {
  display: flex;
  justify-content: center;
}

.screen-upload-evidence > .verify-modal-hidden {
  display: none;
}

.screen-upload-evidence > .verify-modal {
  position: absolute;
  height: 100vh;
  width:100vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: white;
  border-radius: 8px;
}

.screen-upload-evidence > .verify-modal > .img-container {
  width: 310px;
  margin-bottom: 40px
}

.screen-upload-evidence > .verify-modal > .code-verification > div label {
  width: 310px;
  font-size: 16px;
  text-align: center;
}

.screen-upload-evidence > .verify-modal > .code-verification input {
  width: 95px;
  margin-left: 106px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.screen-upload-evidence > .verify-modal button {
  width: 120px;
  margin-top: 50px;
}

.screen-upload-evidence > .form-section-one {
  width: 52%;
  z-index: 1;
}

.screen-upload-evidence > .form-section-two {
  width: 48%;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-top: 72px;
  z-index: 1;
}

.screen-upload-evidence > .form-section-one h1 {
  width: 100%;
  padding-top: 8px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.screen-upload-evidence > .form-section-one > .form-field,
.screen-upload-evidence > .form-section-one > .big-container,
.screen-upload-evidence > .form-section-one > .dropdown-form-field {
  padding-bottom: 5px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

.screen-upload-evidence > .form-section-one > .form-field > .content {
  width: 250px;
  font-weight: 200;
  text-align: left;
  padding-left: 10px;
}

.screen-upload-evidence > .form-section-one > .form-field > label,
.screen-upload-evidence > .form-section-one > .big-container > label,
.screen-upload-evidence > .form-section-one > .dropdown-form-field > label {
  width: 150px;
  font-weight: bold;
  text-align: right;
  margin-right: 3px;
  margin-bottom: 0px;
}

.screen-upload-evidence > .form-section-one > .form-field > .ui.input,
.screen-upload-evidence > .form-section-one > .big-container > form .ui.textarea {
  width: 280px;
  padding-bottom: 2px;
  height: 30px;
  font-size: 12px;
}

.screen-upload-evidence > .form-section-one > .big-container > form .ui.textarea {
  min-height: 50px;
}

.screen-upload-evidence > .form-section-one > .dropdown-form-field {
  height: 32px;
  margin-bottom: 2px;
}

.screen-upload-evidence > .form-section-one > .dropdown-form-field > .section-dropdown {
  font-size: 10px;
  width: 278px;
  padding: 7px;
  margin-left: 2px;
  border-radius: 5px;
}

.screen-upload-evidence > .form-section-one > .big-container > label {
  display: flex;
  float: left;
  justify-content: flex-end;
  margin-top: 8px;
}

.screen-upload-evidence > .form-section-one > .big-container > form textarea {
  min-height: 50px;
  width: 280px;
  padding-left: 10px;
  margin-left: 1px;
  border-radius: 5px;
}

.screen-upload-evidence > .form-section-one > .big-container > form .disabled-textarea {
  min-height: 50px;
  border: 1px solid rgba(85, 85, 85, 0.102);
}

.screen-upload-evidence > .form-section-two  label {
  font-weight: bold;
  padding-right: 5px;
}

.screen-upload-evidence > .form-section-two > .headline {
  text-align: right;
  font-size: 15px;
}

.screen-upload-evidence > .form-section-two > .head-container {
  width: 100%;
  margin-right: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.screen-upload-evidence > .form-section-two > .head-container > label {
  margin: 0px;
}

.screen-upload-evidence > .form-section-two > .head-container input {
  width: 130px;
  padding: 2px;
  text-align: center;
}

.screen-upload-evidence > .form-section-two > .evidence-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two {
  width: 100%;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .image img {
  background-size: cover;
  background-position: center;
  height: 209px;
  width: 150px;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
  .notes-container {
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 2px;
  display: flex;
  justify-content: flex-end;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
  .notes-container label {
  margin-top: 5px;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
  .notes-container form textarea {
  min-height: 50px;
  height: 95px;
  padding-left: 10px;
  border-radius: 5px;
  align-items: end;
  width: 22vw;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
  .add-button {
  float: right;
  text-align: center;
  margin-top: 34px;
  height: 30px;
  width: 30px;
  padding: 6px;
  background-color: #003F66;
  color: #FFFFFF;
  opacity: .9;
  margin-right: 0px;
}

.screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
  .add-button .plus.icon {
  margin: 0px;
}

.screen-upload-evidence > .form-section-two > .record-incidence-table {
  width: 100%;
  height: 40px;
  margin-top: 25px;
}

.screen-upload-evidence > .form-section-two > .foot-actions {
  display: flex;
  justify-content: flex-end;
}

.screen-upload-evidence > .form-section-two > .foot-actions button {
  color: #FFFFFF;
  height: 30px;
  width: 83px;
  font-size: 12px;
  margin-left: 12px;
  margin-right: 0px;
}

.screen-upload-evidence > .form-section-two > .foot-actions > .download-button {
  background-color: #838383;
  width: 120px;
}

.screen-upload-evidence > .form-section-two > .foot-actions > .reactivate-button {
  background-color: #1678C2;
  width: 140px;
}

.reactivate-confirmation-modal > .modal-dialog > .modal-content > .modal-body{
  text-align: center;
}

@media screen and (min-width: 1447px) {
  .screen-upload-evidence > h1 {
    margin-left: 158px;
  }

  .screen-upload-evidence > .form-section-one {
    padding: auto;
    margin: auto;
    margin-left: 158px;
  }

  .screen-upload-evidence > .form-section-two {
    padding: auto;
    margin: auto;
    margin-right: 158px;

  }

  .screen-upload-evidence > .form-section-one > .form-field > .ui.input,
  .screen-upload-evidence > .form-section-one > .big-container > form textarea,
  .screen-upload-evidence > .form-section-one > .big-container > form .ui.textarea,
  .screen-upload-evidence > .form-section-one > .dropdown-form-field > .section-dropdown {
    width: 22vw;
  }

  .screen-upload-evidence > .form-section-two > .evidence-container > .image img {
    height: 32vh;
    width: 15vw;
  }
}

@media screen and (min-width: 1300px) {
  .screen-upload-evidence > .form-section-one {
    margin-left: 50px;
  }
}

@media screen and (max-width: 880px) {
  .screen-upload-evidence {
    flex-direction: column;
  }

  .screen-upload-evidence > .verify-modal {
    height: calc(100vh + 300px);
    margin-bottom: 400px;
  }

  .screen-upload-evidence > .form-section-one,
  .screen-upload-evidence > .form-section-two {
    width: 100%;
    padding: 15px;
    padding-left: 0px;
  }

  .screen-upload-evidence > .form-section-one > .form-field,
  .screen-upload-evidence > .form-section-one > .dropdown-form-field,
  .screen-upload-evidence > .form-section-one > .big-container {
    width: 450px;
  }

  .screen-upload-evidence > .form-section-one > .form-field > .ui.input,
  .screen-upload-evidence > .form-section-one > .big-container > form textarea,
  .screen-upload-evidence > .form-section-one > .big-container > form .ui.textarea,
  .screen-upload-evidence > .form-section-one > .dropdown-form-field > .section-dropdown {
    width: 250px;
  }

  .screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
    .notes-container form textarea {
    height: 18vh;
    width: 41vw;
  }

  .screen-upload-evidence > .form-section-two > .evidence-container > .image img {
    height: 30vh;
    margin: 20px;
  }

  .screen-upload-evidence > .form-section-two > .evidence-container > .form-field-two >
    .notes-container {
    margin-left: 0px;
  }

  .screen-upload-evidence > .form-section-two  div > .table-responsive {
    padding-left: 15px;
  }

  .screen-upload-evidence > .form-section-two > .foot-actions button {
    font-size: 11px;
  }
}
