.screen-daily-status {
  display: flex;
  flex-direction: column;
}

.screen-daily-status > .search-bar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.screen-daily-status > .search-bar > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.screen-daily-status > .search-bar > .form-field > label {
  margin: 0;
  margin-right: 5px;
}

.screen-daily-status > .search-bar > .form-field > label + * {
  font-size: 10px;
}

.screen-daily-status > .search-bar > .form-field:first-child > label + *,
.screen-daily-status > .search-bar > .form-field:nth-child(2) > label + * {
  width: 300px;
}

.screen-daily-status > .search-bar > .form-field.date > .react-datepicker-wrapper > .react-datepicker__input-container > input {
  height: 30px;
  border-radius: 3px;
  border: 1px solid #ddd;
  width: 150px;
  padding: 0px 10px;
}

.screen-daily-status > .search-bar > button {
  font-size: 12px;
}

.screen-daily-status > .table > div > .table-responsive {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
