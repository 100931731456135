.screen-assignment-participation {
  margin-top: 10px;
}

.screen-assignment-participation > .download-button-container {
  display: grid;
  justify-content: flex-end;
}

.screen-assignment-participation > .content > .head {
  display: flex;
}

.screen-assignment-participation > .content > .head > .search-bar {
  display: flex;
  align-items:center;
  margin-top: 5px;
}

.screen-assignment-participation > .content > .head > .search-bar label {
  font-weight: bold;
  margin-left: 7px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 0px;
}

.screen-assignment-participation > .content > .head > .search-bar > .plant-container {
  display: flex;
  flex-direction: row;
  min-width: 250px;
  max-width: 340px;
  height: 33px;
}

.screen-assignment-participation > .content > .head > .search-bar >
  .plant-container .ui.fluid.search.selection {
  font-size: 12px;
  padding: 10px;
}

.screen-assignment-participation > .content > .head > .search-bar >
  .dates-container .ui.mini.form {
  display: flex;
  flex-direction: row;
}

.screen-assignment-participation > .content > .head > .search-bar >
  .dates-container .ui.mini.form > .react-datepicker-wrapper >
  .react-datepicker__input-container input {
    width: 170px;
    height: 33px;
  }

  .screen-assignment-participation > .content > .head > .search-bar > .search-container {
    background-color: var(--primary);
    border-radius: 5px;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
  }

  .screen-assignment-participation > .content > .head > .search-bar >
    .search-container button {
    font-size: 12px;
  }

  .screen-assignment-participation > .content > .head > .indicators-container {
    margin-left: calc(100vw - 800px);
    text-align: right;
  }

  .screen-assignment-participation > .content > .head > .indicators-container >
    .container {
    padding: 0px;
  }

  .screen-assignment-participation > .content > .head > .indicators-container >
    .container > .title {
    margin-right: 7px;
    font-weight: bold;
  }

  .screen-assignment-participation > .content > .table {
    margin-top: 5px;
    height: calc(100vh - 280px);
    min-height: 200px;
    overflow: auto;
    display: flex;
  }


@media only screen and (max-width: 800px) {
  .screen-assignment-participation > .content > .head,
  .screen-assignment-participation > .content > .head > .search-bar {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: auto;
    margin-top: 20px;
  }

  .screen-assignment-participation > .content > .head > .indicators-container,
  .screen-assignment-participation > .content > .head > .indicators-container > .container {
    display: flex;
    flex-direction: row;
  }

  .screen-assignment-participation > .content > .head > .indicators-container > .container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
  }

  .screen-assignment-participation > .content > .head > .indicators-container > .container > .title {
    margin-bottom: 0px;
  }

  .screen-assignment-participation > .content > .head > .search-bar > .plant-container {
    width: 300px;
  }

  .screen-assignment-participation > .download-button-container,
  .screen-assignment-participation > .content > .head > .search-bar > .plant-container,
  .screen-assignment-participation > .content > .head > .search-bar > .dates-container ,
  .screen-assignment-participation > .content > .head > .search-bar > .search-container {
    margin-bottom: 5px;
    justify-content: flex-end;
    align-content: flex-end;
  }

  .screen-assignment-participation > .content > .head > .indicators-container {
    text-align: center;
    margin: auto;
  }

  .screen-assignment-participation > .content > .table {
    margin-top: 0px;
    height: calc(100vh - 440px);
  }
}
