.c-main-layout {
  height: 50px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: white;
  background-color: var(--primary);
}

.c-main-layout > .sidebar-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.c-main-layout > .sidebar-button > img {
  cursor: pointer;
  width: 20px;
}

.c-main-layout > .sidebar-button > span {
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
}

.c-main-layout > .plant-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-main-layout > .plant-info > img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.c-main-layout > .plant-info > span {
  font-size: 20px;
}

.c-main-layout > .user-menu {
  display: flex;
  justify-content: flex-end;
}

.c-main-layout > .user-menu > .dropdown,
.c-main-layout > .user-menu > .dropdown:hover,
.c-main-layout > .user-menu > .dropdown.active,
.c-main-layout > .user-menu > .dropdown.active:hover {
  background-color: transparent;
  color: white;
}

.app-container {
  padding: 5px 15px;
  overflow: auto;
  height: calc(100vh - 50px);
}

.c-sidebar {
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  overflow: auto;
  background-color: white;
  height: 100%;
}

.c-sidebar > img {
  width: 90%;
  cursor: pointer;
}

.c-sidebar > span {
  width: 90%;
  text-align: right;
  margin-top: 5px;
  font-size: 0.65rem;
  font-weight: 500;
}

.c-sidebar > section {
  width: 100%;
}

.c-sidebar > section > .section-title {
  color: white;
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
  background-color: var(--primary);
  padding: 5px;
}

.c-sidebar > section > .section-title > label {
  width: 100%;
  text-align: center;
  display: block;
}
